import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import { __decorate } from "tslib";
import Loader from "@/components/loader/Loader.vue";
import { debounce } from "lodash";
import axios from "axios";
import { VisibilityEnum } from "@/models/visibilityEnum";
import UserCell from "@/components/user/UserCell.vue";
import SpeachCell from "@/components/speach/SpeachCell.vue";
import WTag from "@/components/wrappers/w-tag.vue";
import WDate from "@/components/wrappers/w-date.vue";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import { isNullOrUndefined } from "@tinymce/tinymce-vue/lib/es2015/main/ts/Utils";
import WInput from "@/components/form/w-input.vue";
import WDatePickerRange from "@/components/form/w-date-picker-range.vue";
import UserDetails from "@/components/user/UserDetails.vue";
import SidePanel from "@/components/layout/SidePanel.vue";
import SpeachDetails from "@/components/speach/SpeachDetails.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import { confirmation } from "@/util/dialog";
import { deleteSpeach, goToMonitorSpeach, goToSpeach, goToSpeachEdition, goToTemplates, refresh, restoreSpeach } from "@/util/routeUtil";
import { Events } from "@/events/events";
import WToggle from "@/components/form/w-toggle.vue";
import ScormExportDialog from "@/components/speach/ScormExportDialog.vue";
import BatchUpdateSpeach from "@/components/speach/BatchUpdateSpeach.vue";
import { SpeachState } from "@/models/speachStateEnum";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import InfiniteLoading from "vue-infinite-loading";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import dayjs from "dayjs";
import { Component, Prop, Vue, Ref } from "vue-facing-decorator";
let AdminListSpeaches = class AdminListSpeaches extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "defaultFilterState", void 0);
    _defineProperty(this, "emptyState", void 0);
    _defineProperty(this, "filterTitle", void 0);
    _defineProperty(this, "filterAuthor", void 0);
    _defineProperty(this, "filterCategory", void 0);
    _defineProperty(this, "workflowEnabled", void 0);
    _defineProperty(this, "scormClassicEnabled", void 0);
    _defineProperty(this, "pinnedEnabled", void 0);
    _defineProperty(this, "tableRef", void 0);
    // noinspection JSMismatchedCollectionQueryUpdate: the listSpeaches parameter is used as data for <el-table> line 44
    _defineProperty(this, "listSpeaches", []);
    _defineProperty(this, "currentPage", 1);
    _defineProperty(this, "totalSpeaches", 0);
    _defineProperty(this, "availableCategories", []);
    _defineProperty(this, "filterSort", "LAST_MAJ");
    _defineProperty(this, "filterByTitle", '');
    _defineProperty(this, "filterByAuthor", '');
    _defineProperty(this, "filterByState", '');
    _defineProperty(this, "filterByVisibilities", []);
    _defineProperty(this, "filterByCategories", []);
    _defineProperty(this, "filterInWorkflow", null);
    _defineProperty(this, "filterPinnedOnHome", null);
    _defineProperty(this, "filterOrder", "DESC");
    _defineProperty(this, "calendarRange", null);
    _defineProperty(this, "fixeBar", false);
    _defineProperty(this, "infiniteLoadingEnd", false);
    _defineProperty(this, "busyInfiniteLoading", false);
    _defineProperty(this, "gridID", new Date().getTime());
    _defineProperty(this, "sidePanelVisible", false);
    _defineProperty(this, "selectedSpeach", null);
    _defineProperty(this, "multiselectSpeaches", []);
    _defineProperty(this, "editInfosPopup", false);
    _defineProperty(this, "scormExportPopup", false);
    _defineProperty(this, "goToTemplatesPopup", false);
    _defineProperty(this, "filterIsExpanded", false);
    _defineProperty(this, "isLoaded", false);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/admin/speach');
    _defineProperty(this, "debouncedSearch", debounce(this.reinitSearch, 500));
    _defineProperty(this, "debounceInfinite", debounce($state => {
      this.infiniteHandler($state);
    }, 250));
  }
  async mounted() {
    this.filterByTitle = this.filterTitle;
    this.filterByAuthor = this.filterAuthor;
    this.filterByState = this.defaultFilterState;
    await this.refreshCategories();
    await this.reinitSearch();
  }
  get isEmptyResult() {
    return this.infiniteLoadingEnd && this.totalSpeaches === 0;
  }
  get SpeachState() {
    return SpeachState;
  }
  get VisibilityEnum() {
    return VisibilityEnum;
  }
  get availableStates() {
    return [{
      id: 'PUBLIE',
      name: this.$t('admin.dashboard.speaches.state.published')
    }, {
      id: 'NON_PUBLIE',
      name: this.$t('admin.dashboard.speaches.state.notPublished')
    }, {
      id: 'ARCHIVE',
      name: this.$t('admin.dashboard.speaches.state.archived')
    }];
  }
  get availableWorkflowStatus() {
    return [{
      value: "true",
      name: this.$t('admin.dashboard.speaches.workflowState.inWorkflow')
    }, {
      value: "false",
      name: this.$t('admin.dashboard.speaches.workflowState.notInWorkflow')
    }];
  }
  get workflowStatusCurrentValue() {
    if (this.filterInWorkflow === null) {
      return '';
    } else if (this.filterInWorkflow) {
      return 'true';
    } else {
      return 'false';
    }
  }
  get availablePinnedOnHomeStatus() {
    return [{
      value: "true",
      name: this.$t('admin.dashboard.speaches.pinnedOnHomeState.pinnedOnHome')
    }, {
      value: "false",
      name: this.$t('admin.dashboard.speaches.pinnedOnHomeState.notPinnedOnHome')
    }];
  }
  get pinnedOnHomeCurrentValue() {
    if (this.filterPinnedOnHome === null) {
      return '';
    } else if (this.filterPinnedOnHome) {
      return 'true';
    } else {
      return 'false';
    }
  }
  get availableVisibilities() {
    return [{
      id: VisibilityEnum.PUBLIC,
      name: this.$t('admin.dashboard.speaches.visibility.public')
    }, {
      id: VisibilityEnum.PRIVE,
      name: this.$t('admin.dashboard.speaches.visibility.private')
    }, {
      id: VisibilityEnum.SEMI_PRIVE,
      name: this.$t('admin.dashboard.speaches.visibility.semiPrivate')
    }, {
      id: VisibilityEnum.RESTREINT,
      name: this.$t('admin.dashboard.speaches.visibility.restricted')
    }];
  }
  get selectedCalendarRange() {
    if (this.calendarRange && this.calendarRange[0] && this.calendarRange[1]) {
      return this.$d(this.calendarRange[0]) + ' - ' + this.$d(this.calendarRange[1]);
    }
    return '';
  }
  goToMonitorSpeach(speach) {
    return goToMonitorSpeach(speach);
  }
  goToSpeach(speach) {
    return goToSpeach(speach);
  }
  goToSpeachEdition(speach) {
    return goToSpeachEdition(speach);
  }
  visibilityTag(visibility) {
    let visibilityLabel = this.$t('admin.dashboard.speaches.visibility.public');
    if (visibility === VisibilityEnum.PRIVE) {
      visibilityLabel = this.$t('admin.dashboard.speaches.visibility.private');
    } else if (visibility === VisibilityEnum.SEMI_PRIVE) {
      visibilityLabel = this.$t('admin.dashboard.speaches.visibility.semiPrivate');
    } else if (visibility === VisibilityEnum.RESTREINT) {
      visibilityLabel = this.$t('admin.dashboard.speaches.visibility.restricted');
    }
    return visibilityLabel;
  }
  async refreshCategories() {
    const response = await axios.get(this.BASE_API_SPEACHES + '/categories');
    this.availableCategories = response.data;
    let categoryNumber = parseInt(this.filterCategory);
    if (categoryNumber > 0) {
      this.filterByCategories = [categoryNumber];
    }
  }
  categoryName(categoryId) {
    var _this$availableCatego;
    return ((_this$availableCatego = this.availableCategories.find(category => category.id === categoryId)) === null || _this$availableCatego === void 0 ? void 0 : _this$availableCatego.name) || 'undefined category';
  }
  // noinspection JSUnusedLocalSymbols : This method is used in the template
  getSpeachAuthor(speach) {
    return {
      admin: false,
      anonymous: false,
      approver: false,
      customFieldValues: [],
      email: speach.authorsInfos.author.email,
      firstName: speach.authorsInfos.author.label,
      groups: [],
      id: speach.authorsInfos.author.id,
      img: speach.authorsInfos.author.imgUrl,
      lastActiveDate: 0,
      lastName: '',
      trainer: false,
      userID: '',
      createdAt: 0 // unused fake data
    };
  }
  onFixChange(value) {
    this.fixeBar = value;
  }
  reinitSearch() {
    this.currentPage = 1;
    this.infiniteLoadingEnd = false;
    this.listSpeaches = [];
    this.totalSpeaches = 0;
    this.gridID = new Date().getTime();
    this.multiselectSpeaches = [];
    this.getListSpeaches().then(pageW => {
      this.listSpeaches.push(...pageW.result);
      this.totalSpeaches = pageW.total;
      this.isLoaded = true;
    });
  }
  changeSelectedState(newState) {
    if (!this.isLoaded) {
      return;
    }
    this.filterByState = newState;
    this.reinitSearch();
  }
  changeSelectedVisibilities(newVisibilities) {
    if (!this.isLoaded) {
      return;
    }
    this.filterByVisibilities = newVisibilities;
    this.reinitSearch();
  }
  changeSelectedCategories(newCategories) {
    if (!this.isLoaded) {
      return;
    }
    this.filterByCategories = newCategories;
    this.reinitSearch();
  }
  changeWorkflowStatus(newWorkflowStatus) {
    if (!this.isLoaded) {
      return;
    }
    if (newWorkflowStatus === 'true') {
      this.filterInWorkflow = true;
    } else if (newWorkflowStatus === 'false') {
      this.filterInWorkflow = false;
    } else {
      this.filterInWorkflow = null;
    }
    this.reinitSearch();
  }
  changePinnedOnHomeStatus(newPinnedOnHomeStatus) {
    if (!this.isLoaded) {
      return;
    }
    if (newPinnedOnHomeStatus === 'true') {
      this.filterPinnedOnHome = true;
    } else if (newPinnedOnHomeStatus === 'false') {
      this.filterPinnedOnHome = false;
    } else {
      this.filterPinnedOnHome = null;
    }
    this.reinitSearch();
  }
  changeCalendarRange(newCalendarRange) {
    if (!this.isLoaded) {
      return;
    }
    this.calendarRange = newCalendarRange;
    this.reinitSearch();
  }
  async getListSpeaches() {
    const dateFormat = 'YYYY-MM-DD';
    const result = await axios.get(this.BASE_API_SPEACHES, {
      params: {
        page: this.currentPage,
        sort: this.filterSort,
        searchTitle: this.filterByTitle !== '' ? this.filterByTitle : null,
        searchAuthor: this.filterByAuthor !== '' ? this.filterByAuthor : null,
        state: this.filterByState,
        visibilities: JSON.stringify(this.filterByVisibilities),
        categories: JSON.stringify(this.filterByCategories),
        inWorkflow: this.filterInWorkflow,
        pinnedOnHome: this.filterPinnedOnHome,
        startDate: this.calendarRange ? dayjs(this.calendarRange[0]).format(dateFormat) : null,
        endDate: this.calendarRange ? dayjs(this.calendarRange[1]).format(dateFormat) : null,
        asc: this.filterOrder === "ASC"
      }
    });
    return result.data;
  }
  infiniteHandler($state) {
    // fix an issue with eltable, when scroll down, that trig infinite scroll all time
    const page = document.getElementById('page');
    page.scrollTop = page.scrollTop - 100;
    // end of ugly fix
    this.busyInfiniteLoading = true;
    this.infiniteLoadingEnd = false;
    this.currentPage++;
    this.getListSpeaches().then(pageW => {
      if (pageW.result.length > 0) {
        this.listSpeaches.push(...pageW.result);
        this.totalSpeaches = pageW.total;
        $state.loaded();
      } else {
        if (pageW.total === 0) {
          this.listSpeaches = [];
          this.totalSpeaches = 0;
        }
        this.infiniteLoadingEnd = true;
        $state.complete();
      }
      this.busyInfiniteLoading = false;
      this.isLoaded = true;
    });
  }
  handleSpeachesSelectionChange(speaches) {
    if (!this.busyInfiniteLoading) {
      this.multiselectSpeaches = speaches;
    }
  }
  deselectAllUser() {
    this.multiselectSpeaches = [];
    const table = this.tableRef;
    table.clearSelection();
  }
  selectSpeach(speach) {
    if (this.filterByState !== SpeachState.ARCHIVE) {
      // unselect speach (destroy the component) if not there is multiple update (on change)
      this.selectedSpeach = null;
      this.sidePanelVisible = false;
      // next frame reinit le component with the new speach
      this.$nextTick(() => {
        this.selectedSpeach = speach;
        this.sidePanelVisible = true;
      });
    }
  }
  isDelayed(speach) {
    return !isNullOrUndefined(speach.delayedTillTimestamp) && speach.delayedTillTimestamp > Date.now() || !isNullOrUndefined(speach.futureVersionDelayedTillTimestamp) && speach.futureVersionDelayedTillTimestamp > Date.now();
  }
  getPublicationOrUpdatedDate(speach) {
    if (this.filterByState === SpeachState.PUBLIE) {
      return speach.publicationDate;
    } else {
      return speach.lastUpdatedDate;
    }
  }
  clearFilterByTitle() {
    this.filterByTitle = '';
    this.reinitSearch();
  }
  clearFilterByAuthor() {
    this.filterByAuthor = '';
    this.reinitSearch();
  }
  clearFilterByCalendarRange() {
    this.calendarRange = null;
    this.reinitSearch();
  }
  clearFilterByVisibility(visibility) {
    this.filterByVisibilities = this.filterByVisibilities.filter(selectedVisibility => selectedVisibility !== visibility);
    this.reinitSearch();
  }
  clearFilterByCategory(category) {
    this.filterByCategories = this.filterByCategories.filter(selectedCategory => selectedCategory !== category);
    this.reinitSearch();
  }
  clearFilterByWorkflowState() {
    this.filterInWorkflow = null;
    this.reinitSearch();
  }
  clearFilterByPinnedOnHome() {
    this.filterPinnedOnHome = null;
    this.reinitSearch();
  }
  clearAllFilters() {
    this.filterByTitle = '';
    this.filterByAuthor = '';
    this.filterByVisibilities = [];
    this.filterByCategories = [];
    this.calendarRange = null;
    this.filterInWorkflow = null;
    this.filterPinnedOnHome = null;
    this.reinitSearch();
  }
  closeSidePanel() {
    this.sidePanelVisible = false;
  }
  batchEditSpeaches() {
    WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
    this.editInfosPopup = false;
    this.multiselectSpeaches = [];
    this.reinitSearch();
  }
  closeBatchEdit() {
    this.editInfosPopup = false;
  }
  archiveListSpeaches() {
    confirmation(this.$t('thumbnail.menu.archiveConfirm'), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(() => {
      for (const speach of this.multiselectSpeaches) {
        deleteSpeach(speach);
        this.listSpeaches = this.listSpeaches.filter(s => s.id !== speach.id);
      }
      this.multiselectSpeaches = [];
    });
  }
  async restoreListSpeaches() {
    for (const speach of this.multiselectSpeaches) {
      await restoreSpeach(speach);
      this.listSpeaches = this.listSpeaches.filter(s => s.id !== speach.id);
    }
    this.multiselectSpeaches = [];
    refresh();
  }
  async restoreSpeach(speach) {
    restoreSpeach(speach).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachRestored"));
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachRestoreFailed"));
    }).finally(() => {
      this.reinitSearch();
    });
  }
  shareSpeach(speach) {
    trig(Events.DIFFUSER_COUR, {
      id: speach.id,
      isPublie: speach.publication,
      isDuringDelayPeriod: speach.delayed_till
    });
    this.multiselectSpeaches = [];
  }
  duplicateSpeach(speach) {
    axios.put(this.BASE_API_SPEACHES + '/duplicate', null, {
      params: {
        dataType: "json",
        speachId: speach.speachID
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachDuplicated"));
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachDuplicatedFailed"));
    }).finally(() => {
      this.multiselectSpeaches = [];
      this.reinitSearch();
    });
  }
  createTemplate(speach) {
    axios.put(this.BASE_API_SPEACHES + '/createTemplate', null, {
      params: {
        dataType: "json",
        speachId: speach.id
      }
    }).then(() => {
      this.goToTemplatesPopup = true;
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.templateFailed"));
    }).finally(() => {
      this.multiselectSpeaches = [];
      this.reinitSearch();
    });
  }
  goToTemplates() {
    this.goToTemplatesPopup = false;
    goToTemplates();
  }
};
__decorate([Prop({
  default: 'PUBLIE'
})], AdminListSpeaches.prototype, "defaultFilterState", void 0);
__decorate([Prop({
  default: true
})], AdminListSpeaches.prototype, "emptyState", void 0);
__decorate([Prop({
  default: ''
})], AdminListSpeaches.prototype, "filterTitle", void 0);
__decorate([Prop({
  default: ''
})], AdminListSpeaches.prototype, "filterAuthor", void 0);
__decorate([Prop({
  default: ''
})], AdminListSpeaches.prototype, "filterCategory", void 0);
__decorate([Prop({
  default: false
})], AdminListSpeaches.prototype, "workflowEnabled", void 0);
__decorate([Prop({
  default: false
})], AdminListSpeaches.prototype, "scormClassicEnabled", void 0);
__decorate([Prop({
  default: false
})], AdminListSpeaches.prototype, "pinnedEnabled", void 0);
__decorate([Ref()], AdminListSpeaches.prototype, "tableRef", void 0);
AdminListSpeaches = __decorate([Component({
  //methods: {goToMonitorSpeach, goToSpeachEdition, goToSpeach},
  components: {
    ConfirmDialog,
    WSelectAdvanced,
    ScormExportDialog,
    WToggle,
    BatchUpdateSpeach,
    WDialog,
    StandardButton,
    SpeachDetails,
    SidePanel,
    UserDetails,
    WDatePickerRange,
    InfiniteLoading,
    WInput,
    WToolTip,
    Icon,
    WDate,
    WTag,
    SpeachCell,
    UserCell,
    Loader
  }
})], AdminListSpeaches);
export default AdminListSpeaches;